import React from 'react';
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import { MainContext } from 'src/Context';
import { Table, Button, ConfigProvider, Modal } from 'antd';
import { allinOne, sortingAsc, removeDuplicate, removeDuplicateObj, msg, allinPut } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { Input, Select } from 'antd';
import { statusCircle } from "src/layouts/widgets/CustomLayout/index"

import { socket } from 'src/utils/socket';
import { spining } from 'src/layouts/widgets/CustomLayout';

class StanjeProizvodnje extends React.Component {
    static contextType = MainContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            _tableData: [],
            projects: [],
            project: undefined,
            search: "",
            screenHeight: window.innerHeight,
            spin: false,
            modalOpen2: false,
            record: false,
            call: false,
            input: "",
            _input: "",
            name: "",
            addNewUser: false,
            KeyPad: false
        };
    }

    getUserOn = (e) => {
        // console.log("userOn", e)
        setTimeout(() => {
            this._isMounted = true
            this.callAll()
        }, 2000)
    }

    getUserOnV2 = (e) => {
        const { _tableData, tableData } = this.state
        // console.log("userOnV2", e)
        if (this._isMounted === true && e?.data?.operation) {
            const _asset = Object.values(e?.data?.assets).flat()
            const operation = e?.data?.operation
            const newTableData = [...tableData].map(el => {
                if (el?.operationId === operation?.id) {
                    const _op = operation?.workers.find(f => f?.userId === el?.userId)
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const op = _op ? _op : {}
                    const as = _as ? _as : {}
                    if (_op.active === 0) {
                        return { ...as, key: el?.key }
                    } else {
                        return { ...el, ...op, ...as }
                    }
                } else {
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const as = _as ? _as : {}
                    if (as?.operationId !== el?.operationId) {
                        const _op = operation?.workers.find(f => f?.userId === el?.userId)
                        const op = _op ? _op : {}
                        return { ...el, ...op, ...as, businessOrder: as?.projectCode, workOrder: as?.WOCode }
                    } else {
                        return el
                    }
                }
            })
            const _newTableData = [..._tableData].map(el => {
                if (el?.operationId === operation?.id) {
                    const _op = operation?.workers.find(f => f?.userId === el?.userId)
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const op = _op ? _op : {}
                    const as = _as ? _as : {}
                    if (_op.active === 0) {
                        return { ...as, key: el?.key }
                    } else {
                        return { ...el, ...op, ...as }
                    }
                } else {
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const as = _as ? _as : {}
                    if (as?.operationId !== el?.operationId) {
                        const _op = operation?.workers.find(f => f?.userId === el?.userId)
                        const op = _op ? _op : {}
                        return { ...el, ...op, ...as, businessOrder: as?.projectCode, workOrder: as?.WOCode }
                    } else {
                        return el
                    }
                }
            })
            this.setState({
                tableData: newTableData,
                _tableData: _newTableData,
            })
        } else {
            setTimeout(() => {
                this._isMounted = true
                this.callAll()
            }, 2000)
        }

    }

    getUserOnV3 = (e) => {
        const { _tableData, tableData } = this.state
        // console.log("userOnV2", e)
        if (this._isMounted === true && e?.data?.operation) {
            const _asset = Object.values(e?.data?.assets).flat()
            const operation = e?.data?.operation
            const newTableData = [...tableData].map(el => {
                if (el?.operationId === operation?.id) {
                    const _op = operation?.workers.find(f => f?.userId === el?.userId)
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const op = _op ? _op : {}
                    const as = _as ? _as : {}
                    if (_op.active === 0) {
                        return { ...as, key: el?.key }
                    } else {
                        return { ...el, ...op, ...as }
                    }
                } else {
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const as = _as ? _as : {}
                    if (as?.operationId !== el?.operationId) {
                        const _op = operation?.workers.find(f => f?.userId === el?.userId)
                        const op = _op ? _op : {}
                        return { ...el, ...op, ...as, businessOrder: as?.projectCode, workOrder: as?.WOCode }
                    } else {
                        return el
                    }
                }
            })
            const _newTableData = [..._tableData].map(el => {
                if (el?.operationId === operation?.id) {
                    const _op = operation?.workers.find(f => f?.userId === el?.userId)
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const op = _op ? _op : {}
                    const as = _as ? _as : {}
                    if (_op.active === 0) {
                        return { ...as, key: el?.key }
                    } else {
                        return { ...el, ...op, ...as }
                    }
                } else {
                    const _as = _asset.find(f => f?.userId === el?.userId)
                    const as = _as ? _as : {}
                    if (as?.operationId !== el?.operationId) {
                        const _op = operation?.workers.find(f => f?.userId === el?.userId)
                        const op = _op ? _op : {}
                        return { ...el, ...op, ...as, businessOrder: as?.projectCode, workOrder: as?.WOCode }
                    } else {
                        return el
                    }
                }
            })
            this.setState({
                tableData: newTableData,
                _tableData: _newTableData,
            })
        } else {
            setTimeout(() => {
                this._isMounted = true
                this.callAll()
            }, 2000)
        }

    }

    componentDidMount() {
        this._isMounted = true;
        // console.log(this.props)
        window.addEventListener('resize', this.handleResize)
        this.callAll()
        // socket.connect()
        socket.on('usersOn', this.getUserOn)
        socket.on('usersOnV2', this.getUserOnV2)
        socket.on('usersOnV3', this.getUserOnV3)
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off("usersOnV2", this.getUserOnV2)
        socket.off("usersOnV3", this.getUserOnV3)
        socket.off("usersOn", this.getUserOn)
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        const { contextChangeTrue } = this?.context?.state;
        if (contextChangeTrue) {
            this.callAll()
            this.context.callMe(false)
        }
    }
    callAll() {
        this.getOrders()
    }
    handleResize = () => {
        this.setState({ screenHeight: window.innerHeight })
    }

    getOrders = async () => {
        const { lang } = this.context.state;
        const result = await allinOne("getUsersOnOperation", {})
        const result1 = await allinOne("getSpecDocument", {})
        if (result) {
            const _asset = Object.values(result1?.assets).flat()
            //result1?.assetsArr
            //const asset = removeDuplicateObj([...result, ..._asset], "userId")
            const asset = _asset.map(el => {
                const fA = result.find(f => f?.userId === el?.userId)
                if (fA) {
                    return { ...el, ...fA }
                } else {
                    return el
                }
            })
            // console.log(asset)
            const res = asset.map((el, i) => ({ ...el, key: i + 1 }))
            const pr = result.map(el => ({ value: el?.businessOrder, label: el?.businessOrder }))
            const PR = sortingAsc(removeDuplicate(pr), ["label"])
            // console.log({
            //     pr,
            //     PR
            //   })
            this.setState({
                tableData: res,
                _tableData: res,
                projects: PR
            })
        } else {
            msg("warning", (lang?.["noUserDefined"] ?? "noUserDefined"), 2)
        }


    }

    handleChange = (e) => {
        const { _tableData, project } = this.state
        const td = (e && e.length > 0) ? _tableData?.filter(el => el?.labelUser?.toLowerCase().includes(e.toLowerCase())
            || el?.productName?.toLowerCase().includes(e.toLowerCase())
            || el?.workOrder?.toLowerCase().includes(e.toLowerCase())
            || el?.businessOrder?.toLowerCase().includes(e.toLowerCase())
        )
            : _tableData
        if (project) {
            const op = td.filter(f => f?.businessOrder === project)
            const _op = op.map((el, i) => ({ ...el, key: i + 1 }))
            this.setState({
                tableData: _op,
                search: e
            });
        } else {
            this.setState({
                tableData: td,
                search: e
            });
        }
    }
    onChange = (value, selected) => {
        const { _tableData, search } = this.state
        const td = (search && search.length > 0) ? _tableData?.filter(el => el?.labelUser?.toLowerCase().includes(search.toLowerCase())
            || el?.productName?.toLowerCase().includes(search.toLowerCase())
            || el?.workOrder?.toLowerCase().includes(search.toLowerCase())
            || el?.businessOrder?.toLowerCase().includes(search.toLowerCase())
        )
            : _tableData
        if (value) {
            const op = td.filter(f => f?.businessOrder === selected?.value)
            const _op = op.map((el, i) => ({ ...el, key: i + 1 }))
            this.setState({
                tableData: _op,
                project: value
            });
        } else {
            this.setState({
                tableData: td,
                project: value
            });
        }

        // console.log(e)
    }


    unPause = async (e) => {
        const result = await allinOne("getSpecDocument", {})
        // console.log(result)
        if (result) {
            const workers = result?.assets?.[e?.assetId].map((el) => {
                if (el?.userId === e?.userId) {
                    return ({ ...el, eventStartTimestamp: null, code: null, subCode: null })
                } else {
                    return el
                }
            })
            const newData = {
                ...result?.assets,
                [e?.assetId]: workers
            }
            this.setState({ spin: true }, () => {
                setTimeout(async () => {
                    // const result = await allinPut("updateUsersOnAsset", { assetId: e?.assetId, workers })
                    // if (result) this.getOrders()
                    // socket.connect()
                    // socket.emit("usersOn", newData)
                    const data = {
                        topic: "raw_data",
                        userId: e?.userId,
                        assetId: e?.assetId,
                        parameters: {
                            rfp_dataTimestamp: Date.now(),
                            rfp_userInterrupt: 0,
                            rfp_userInterruptCode: 0,
                            rfp_userInterruptSubCode: 0,
                        }
                    }
                    socket.emit("sentToKafka", { ...data })
                    // socket.emit("operationBoardRefresh", data)
                    this.setState({ spin: false, modalOpen2: false })
                }, 500)
            })
        }
    }

    renderValues = (e, el) => {
        const a = el?.eventStartTimestamp ? (el?.code === "U0001" && el?.subCode === "003" ? 3 : 2) : (el?.operationId ? 1 : 10)
        switch (a) {
            case 0: return <>{" "}{statusCircle("#ff0000", 13)}</>;
            case 1: return <>{" "}{statusCircle("#0b6f02", 13)}</>;
            case 2: return <>{" "}{statusCircle("#ff0000", 13)}</>;
            case 3: return <>{" "}{statusCircle("#FF7518", 13)}</>;
            default: return ""
        }
    }


    handleDelete = async (key, record) => {
        // const newData = data.map((item) => {
        // if (item.key === key) {
        // return { ...item, active: 0 }
        // } else {
        // return item
        // }
        // });

        // setData(newData);
        // setDataTable(newData)
        // console.log(record)
        const e1 = {
            active: 1,
            assetId: record?.assetId,
            labelAsset: record?.labelAsset,
            labelUser: record?.labelUser,
            erp_userId: record?.erp_userId,
            // timestamp,
            userId: record?.userId
        }
        //console.log(e1)
        const result1 = await allinPut("removeUserOnOperationsV2", e1)
        // console.log({
        //   id,
        //   e
        // })
        // alert("Not saved, coming in next release")
        if (result1) {
            const op2 = {
                data: {
                    operation: result1?.operation,
                    assets: result1?.assets
                }
            }
            socket.emit("operationBoardRefresh", op2)
            socket.emit("usersOnV2", op2)
            this.setState({ modalOpen2: false })
            // this.getOrders()
        }

        //const result = await allinPut("updateWorkers001", { id: operation?._id, workers: newData })
        // if (result?.success) {
        //   msg("success", (lang?.["saved"] ?? "saved"), 2)
        // }
    };

    handleOk = () => {
        // _props?.setUserToAsset(props, "removeUserAssetMgmt")
        this.setState({ modalOpen2: false })
    };

    handleCancel = () => {
        this.setState({ modalOpen2: false })

        // setIsModalOpen(false);
        // props.closeModal()
    };

    addUserToAsset = async (record) => {
        const { style } = this.context.state
        const c = await import("src/layouts/widgets/Core/KeyPad")
        const KeyPad = c?.default
        this.setState({
            KeyPad: <KeyPad
                pressbutton={this.pressbutton}
                backState={this.backState}
                handleSubmit={this.handleSubmit}
                style={style}
                labelButton={"finish"}
                newContext={this.context}
            />,
            record: record,
            call: "end",
            name: "",
            input: "",
            modalOpen2: true
        })
    }

    callModalEnd = async (record, users) => {
        const { style } = this.context.state
        const c = await import("src/layouts/widgets/Core/KeyPad")
        const KeyPad = c?.default
        this.setState({
            KeyPad: <KeyPad
                pressbutton={this.pressbutton}
                backState={this.backState}
                handleSubmit={this.handleSubmit}
                style={style}
                labelButton={"finish"}
                newContext={this.context}
            />,
            record: record,
            call: "pause",
            name: "",
            input: "",
            modalOpen2: true
        })
    }

    handleSubmit = (e) => {
        const { lang } = this.context.state;
        this.setState({ addNewUser: true }, () => {
            const { record, name, addNewUser, call } = this.state
            if (addNewUser) {
                if (call === "end") {
                    const search = record.userId === name?._id
                    this.setState({ addNewUser: false })
                    if (search) {
                        this.handleDelete(record.key, record)
                    } else {
                        msg("warning", lang?.["noUser"] ?? "noUser", 2)
                    }
                } else {
                    const search = record.userId === name?._id
                    this.setState({ addNewUser: false })
                    if (search) {
                        this.unPause(record)
                    } else {
                        msg("warning", lang?.["noUser"] ?? "noUser", 2)
                    }
                }

            }
        })
    }

    pressbutton = (e) => {
        // console.log(e)
        const _e = e.toString()
        this._setInput(_e)
        // const _input = prevState(e)
        // setInput((prevState) => { return { input: prevState.input + e }})
        // setState({ ...state, input: e })
    }

    backState = (e) => {
        this._setInput("-1")
    }

    _setInput = (_input) => {
        const { input } = this.state
        if (_input === "-1") {
            this.setState({ input: input.slice(0, -1) }, () => {
                const { input } = this.state
                this.checkInput(input)
            })
        } else if (_input) {
            this.setState({ input: input + _input }, () => {
                const { input } = this.state
                this.checkInput(input)
            })
        }
    }

    checkInput = (input) => {
        const u = async () => {
            const r = await allinOne("UsersLight", { pin: input })
            this.setState({ name: r })
        }
        const _input = input?.length
        if (_input === 4) {
            u()
        } else {
            this.setState({ name: "" })
        }
    }

    render() {
        const { lang } = this.context.state;
        const cUserId = this.context?.state?.userConfig?.user?.userId
        const { tableData, projects, screenHeight, spin, KeyPad, modalOpen2, input } = this.state
        const columns = [
            {
                title: '#',
                dataIndex: 'key',
                key: 'key',
                width: 35,
                render: (text) => <span>{text}</span>,
            },
            {
                title: lang["name"] ? lang["name"] : "name",
                dataIndex: 'name',
                key: 'key',
                //width: 250,
                render: (text, record) => <span>{record?.labelUser}</span>,
            },
            {
                title: lang["onPause"] ? lang["onPause"] : "onPause",
                dataIndex: 'onPause',
                key: 'key',
                /// width: 100,
                render: (text, record) => <span>{record?.subject ?? ""}</span>,
            },
            {
                title: '#',
                dataIndex: '#',
                width: 100,
                render: (_, record) => {
                    // console.log([spin, record?.userId])
                    return (
                        <Button
                            type="primary"
                            ghost={false}
                            danger={true}
                            disabled={!record?.eventStartTimestamp || spin}
                            // onClick={() => this.unPause(record)}
                            // onClick={() => this.callModalEnd(record)}
                            onClick={() => {
                                if (record?.userId === cUserId) {
                                    this.unPause(record)
                                } else {
                                    this.callModalEnd(record)
                                }
                            }
                            }
                        >
                            {(spin === record?.userId) ? (spining()) : lang?.["unPause"] ?? "unPause"}
                        </Button>
                    )
                },
            },
            {
                title: lang["BusinessOrder"] ? lang["BusinessOrder"] : "Project",
                dataIndex: 'businessOrder',
                key: 'key',
                /// width: 100,
                render: (text, record) => <span>{record?.businessOrder ?? "/"}</span>,
            },
            {
                title: lang["WorkOrder"] ? lang["WorkOrder"] : "WorkOrder",
                dataIndex: 'workOrder',
                key: 'key',
                /// width: 100,
                render: (text, record) => <span>{record?.workOrder ?? "/"}</span>,
            },
            {
                title: lang["productName"] ? lang["productName"] : "productName",
                dataIndex: 'productName',
                key: 'key',
                //width: 250,
                render: (text, record) => <span>{record?.productName ?? "/"}</span>,
            },
            {
                title: lang["productionTime"] ? lang["productionTime"] : "productionTime",
                dataIndex: 'productionTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.productionTime ? dateTimeFormatter("duration", record?.productionTime, "h") : "/"}</span>,
            },
            {
                title: lang["iskanjeTime"] ? lang["iskanjeTime"] : "iskanjeTime",
                dataIndex: 'iskanjeTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.iskanjeTime ? dateTimeFormatter("duration", record?.iskanjeTime, "h") : "/"}</span>,
            },
            {
                title: lang["zastojTime"] ? lang["zastojTime"] : "zastojTime",
                dataIndex: 'zastojTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.zastojTime ? dateTimeFormatter("duration", record?.zastojTime, "h") : "/"}</span>,
            }, {
                title: lang?.["active"] ?? "active",
                dataIndex: 'active',
                key: 'active',
                width: 30,
                align: "center",
                render: (text, record) => {
                    return (
                        <span>{this.renderValues(text, record)}</span>
                    )
                },
            },
            {
                title: '#',
                dataIndex: 'action',
                width: 80,
                render: (_, record) =>
                    <Button
                        type="primary"
                        danger={true}
                        // onClick={() => this.handleDelete(record.key, record)}
                        // onClick={() => this.addUserToAsset(record)}
                        onClick={() => {
                            if (record?.userId === cUserId) {
                                this.handleDelete(record.key, record)
                            } else {
                                this.addUserToAsset(record)
                            }
                        }
                        }
                        disabled={!record?.operationId || record?.eventStartTimestamp}>

                        {lang?.["finish"] ?? "finish"}
                    </Button>
            },

        ]

        const buttonStyle = {
            width: 150,
            height: 50,
            fontSize: 20
        }



        return (
            <Col md="12" style={{ paddingLeft: "0px" }}>
                <div>
                    <Input placeholder={lang?.["search"] ?? "search"} style={{ width: 205, marginRight: 5, marginBottom: 5 }} onChange={(e) => this.handleChange(e.target.value)} />
                    <Select
                        style={{
                            width: 300,
                        }}
                        showSearch
                        onChange={this.onChange}
                        //onSearch={onSearch}
                        allowClear
                        options={projects} />
                </div>
                <div style={{ overflowY: "scroll", height: screenHeight - 150 }}>
                    <Table
                        className="table-striped-rows"
                        columns={columns}
                        dataSource={sortingAsc(tableData, ["labelUser"]).map((el, i) => ({ ...el, key: i + 1 }))}
                        pagination={{
                            position: ["bottomCenter"],
                            defaultPageSize: 20,
                            pageSizeOptions: ["15", "25", "30", "50", "75", "100"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" },
                        }}
                    />
                </div>
                {KeyPad &&
                    <ConfigProvider
                        theme={{
                            components: {
                                Modal: {
                                    contentBg: "none"
                                },
                            },
                        }}
                    >
                        <Modal
                            width={"auto"}
                            styles={{
                                body: {
                                    background: "red"
                                }
                            }}
                            closeIcon={false}
                            destroyOnClose={true}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                            open={modalOpen2}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}>
                            <div style={{
                                position: "fixed",
                                top: 20,
                                left: "50%",
                                // transform: "translate(-50%, -50%)",
                                transform: "translate(-50%)",
                                // float: "right",
                                // padding: 10,
                                background: "#ffffff"
                            }}>
                                <div style={{ paddingTop: 10, background: "#ffffff", height: 30, fontSize: 20, marginBottom: 10 }}>
                                    <b style={{ padding: 10 }}>
                                        {input}
                                    </b>
                                    {/*
                  <b style={{ float: "right" }}>
                    {firstName + " " + lastName}
                  </b>
                  */}
                                </div>
                                {KeyPad}
                                <div>
                                    <Button
                                        ghost={false}
                                        type="primary"
                                        danger={true}
                                        style={{ ...buttonStyle, float: "right", margin: 12 }}
                                        onClick={this.handleCancel}>
                                        {lang?.["close"] ?? "close"}
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </ConfigProvider>
                }
            </Col>
        )
    }
}
export default withRouter(StanjeProizvodnje);

// import React from 'react';
// import { withRouter } from "react-router-dom";
// import { MainContext } from 'src/Context';
// // import { Image } from 'antd';
// // import * as comp from 'antd';

// class StanjeProizvodnje extends React.Component {
//   static contextType = MainContext;
//   _isMounted = false;
//   constructor(props) {
//     super(props)
//     this.state = { ...this.props }
//   }

//   componentDidMount() {
//     this._isMounted = true;
//     this.getData()
//   }

//   getData = () => {
//     if (this._isMounted) {
//       this.setState({
//         title: "text"
//       })

//     }
//   }

//   componentWillUnmount() {
//     this._isMounted = false;
//     this.setState({ title: "" })
//   }

//   render() {
//     // console.log(this.state)
//     return (
//       <div>dihopgpfokjhpkfgjkh</div>
//     )
//   }
// }